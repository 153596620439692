export const cuisineOptions = [
  "African",
  "American",
  "Argentinian",
  "Asian",
  "Bakery",
  "Bangladeshi",
  "Bar / Pub Food",
  "BBQ",
  "Brazilian",
  "Breakfast & Brunch",
  "Bubble Tea",
  "Burgers",
  "Burmese",
  "Burrito",
  "Cajun / Creole",
  "Caribbean",
  "Chilean",
  "Chinese",
  "Taiwanese",
  "Coffee and Tea",
  "Colombian",
  "Dessert",
  "Ecuadorian",
  "Egyptian",
  "Empanada",
  "European",
  "Filipino",
  "Fish & Seafood",
  "French",
  "Georgian",
  "German",
  "Greek",
  "Guetemalan",
  "Halal",
  "Hawaiian",
  "Ice Crean & Frozen Yogurt",
  "Indian",
  "Indonesian",
  "Israeli",
  "Italian",
  "Japanese",
  "Korean",
  "Latin American",
  "Lebanese",
  "Malaysian",
  "Mediterranean",
  "Mexican",
  "Middle Eastern",
  "Modern Australian",
  "Moroccan",
  "Pakistani",
  "Peruvian",
  "Pizza",
  "Poke",
  "Portuguese",
  "Russian",
  "Seafood",
  "Southern",
  "Spanish",
  "Sushi",
  "Steakhouse",
  "Tacos",
  "Thai",
  "Turkish",
  "Vegetarian / Vegan",
  "Venezuelan",
  "Vietnamese",
  "Other",
];

export const countryOptions = [
  "USA",
  "Argentina",
  "Australia",
  "Austria",
  "Bahrain",
  "Bangladesh",
  "Belgium",
  "Brazil",
  "Bulgaria",
  "Canada",
  "Chile",
  "Colombia",
  "Costa Rica",
  "Croatia",
  "Czech Republic",
  "Denmark",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Guatemala",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Ireland",
  "Israel",
  "Italy",
  "Japan",
  "Jordan",
  "Kuwait",
  "Lebanon",
  "Lithuania",
  "Luxembourg",
  "Malaysia",
  "Mexico",
  "Netherlands",
  "New Zealand",
  "Nigeria",
  "Norway",
  "Pakistan",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Saudi Arabia",
  "Scotland",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Thailand",
  "Turkey",
  "UAE",
  "UK",
  "Ukraine",
  "Uruguay",
  "Venezuela",
  "Vietnam",
];

export const stateOptions = [
  "N/A",
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const priceOptions = ["$", "$$", "$$$", "$$$$"];

export const dietaryOptions = ["", "Vegan", "Vegetarian", "Gluten-free"];
